<template>
  <div class="u-s-f">
    <div class="img-c" v-loadingx="loadingFile" @click="$refs.upload.click()">
      <img :src="src" v-if="src"/>
      <div v-else class="no-img">
        <div>未上传签名</div>
        <div>点击上传</div>
      </div>
    </div>
    <input type="file" style="display: none;" @change="startUpload" ref="upload">
  </div>
</template>

<script>
import {
  fileRequest,
  userRequest
} from '@/api'

export default {
  props: {
    fileId: {
      type: Number
    },
    userId: {
      type: Number
    }
  },
  data () {
    return {
      loadingFile: false,
      src: null
    }
  },
  watch: {
    fileId: {
      handler () {
        this.loadFile()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (file.type && file.type.includes('image')) {
          let hisHeadFileId = this.fileId
          if (hisHeadFileId) {
            let res = await this.$dialog.confirm('签名将被覆盖，是否继续操作?')
            if (!res) {
              return
            }
          }
          this.loadingFile = true
          let parm = new FormData()
          parm.append('file', file)
          let fileData = await fileRequest.upload(parm)
          await userRequest.updateSignFile(this.userId, {
            signFileId: fileData.id
          })
          this.$emit('signFileUpdate', fileData.id)
          this.$notice.success({
            title: '系统提示',
            desc: '签名上传完成。'
          })
          this.loadingFile = false
        } else {
          this.$notice.info({
            title: '系统提示',
            desc: '请选择图片文件。'
          })
        }
      }
    },
    async loadFile () {
      this.src = null
      if (!this.fileId) {
        return
      }
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.fileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        this.src = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.u-s-f {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-c {
  cursor: pointer;
  height: 100%;
  width: calc(100% - 50px);
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.worker-data {
  .fm-form-inline {
    padding: 0 0 0 16px !important;
  }
}
</style>
