<style lang="less">
  .no-header-modal {
    .fm-modal-header {display: none;}
    .fm-modal-footer {display: none;}
  }
</style>

<template>
  <div class="auth-user-page">
    <base-sign-page
      title-text="用户"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :tableActionsWidth="350"
      :show-search="true"
      :loading="loading"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      :table-actions-fixed="true"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :need-pagination="true">
    </base-sign-page>
    <fm-modal
      :mask-closable="false"
      :value="openDialog"
      width="800"
      v-if="openDialog"
      theme="mh-withe"
      @cancel="openDialog = false">
      <user-form @close="openDialog = false" :org-tree="orgTree" :roleList="sysRoles" @reload="loadData(true)" :user="chooseData" />
    </fm-modal>
    <fm-modal
      :mask-closable="false"
      :title="chooseData ? chooseData.name + ' 角色权限' : '角色权限'"
      :value="openDialogRole"
      width="800"
      v-if="openDialogRole"
      theme="mh-withe"
      @cancel="openDialogRole = false">
      <UserRoleForm @close="openDialogRole = false" @reload="loadData(true)" :user-id="chooseData.id" />
    </fm-modal>
    <fm-modal
      :mask-closable="false"
      :title="chooseData ? chooseData.name + ' 职务' : '职务'"
      :value="openDialogOrgRole"
      width="800"
      v-if="openDialogOrgRole"
      theme="mh-withe"
      @cancel="openDialogOrgRole = false">
      <UserOrgRoleForm @close="openDialogOrgRole = false" @reload="loadData(true)" :user-id="chooseData.id" />
    </fm-modal>
    <sort-modal ref="sortmodal" :dataList="dataList" @change="sortNumChange"></sort-modal>
    <fm-modal
      :title="(chooseData ? chooseData.name : '') + ' 的签名'"
      v-model="showSignFile"
      width="600px"
      theme="mh-withe">
      <SignFile v-if="showSignFile" :user-id="chooseData.id" @signFileUpdate="signFileUpdate" :file-id="chooseData.signFileId"></SignFile>
    </fm-modal>
    <fm-modal
      class="no-header-modal"
      v-model="agentModal.show"
      width="400px">
      <div slot="header"></div>
      <div slot="close"></div>
      <div style="display: flex;flex-direction: column;justify-content: center;align-items:center;">
        <Icon type="ios-lock" size="50"/>
        <p>授权已获取</p>
        <Divider>APP扫码使用</Divider>
        <img style="width: 180px;" :src="agentModal.img" />
        <Divider>本机电脑使用</Divider>
        <Button long type="primary" @click="pcLogin">电脑登录</Button>
        <Button long type="default" style="margin-top: 10px;" @click="agentModal.show = false">取消</Button>
      </div>
      <div slot="footer"></div>
    </fm-modal>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'
import SignFile from './user/signFile'
import UserForm from './user/form.vue'
import UserRoleForm from './user/roleForm.vue'
import UserOrgRoleForm from './user/orgRoleForm.vue'
import sortModal from './user/sort'

let QRCode = require('qrcode')

import { v4 as uuidv4 } from 'uuid'
import {
  localstorageHelper
} from '@/fmlib/index'

import {
  userRequest as request,
  authRequest
} from '@/api'

import {
  rsaHelper
} from '@/fmlib'

import {
  delUserDeal
} from '@/sys'

export default {
  components: {
    BaseSignPage, UserForm, sortModal, SignFile, UserRoleForm, UserOrgRoleForm
  },
  watch: {},
  computed: {
    tableActions: {
      get () {
        return [{
          label: '签名',
          key: 'sign_file'
        },
        {
          label: '角色',
          key: 'role'
        },
        {
          label: '职务',
          key: 'org_role'
        },
        {
          label: '修改',
          key: 'edit'
        },
        {
          label: '重置密码',
          key: 'reset_pwd'
        },
        {
          label: '代理使用',
          key: 'agent'
        },
        {
          label: '删除',
          key: 'del'
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    titleMenus: {
      get () {
        return [{
          key: 'sort',
          label: '默认排序设置'
        },
        {
          key: 'add',
          label: '新增'
        }].filter(({key}) => this.$authFunsProxy[key])
      }
    },
    userStatusList () {
      return this.$store.getters.userStatusList
    },
    sysRoles: {
      get () {
        return this.$store.getters.roleList.filter(v => v.data.roleType === 'role')
      }
    },
    orgTree () {
      return this.$store.getters.orgTree
    },
    columnList: {
      get () {
        return [{
          field: 'name',
          sort: true,
          title: '姓名'
        },
        // {
        //   field: 'nickname',
        //   sort: true,
        //   title: '昵称'
        // },
        {
          field: 'account',
          sort: true,
          title: '账号'
        },
        {
          field: 'phone',
          sort: true,
          title: '电话'
        },
        {
          field: 'idNo',
          sort: true,
          title: '身份证号'
        },
        {
          field: 'orgRoleLabels',
          sort: true,
          title: '组织架构'
        },
        {
          field: 'roleNames',
          sort: true,
          title: '角色权限'
        },
        {
          field: 'sortNum',
          sort: true,
          title: '排序值'
        },
        {
          field: 'statusText',
          sort: true,
          title: '状态',
          filters: this.userStatusList.map(v => {
            return {
              label: v.label,
              value: v.label
            }
          })
        }]
      }
    }
  },
  async created () {
    this.loadData()
  },
  methods: {
    signFileUpdate (fileId) {
      this.chooseData.signFileId = fileId
    },
    sortNumChange (id, sortNum) {
      let data = this.dataList.find(v => v.id === id)
      data.sortNum = sortNum
      let list1 = this.dataList.filter(v => v.sortNum !== null)
      let List2 = this.dataList.filter(v => v.sortNum === null)
      list1.sort((a, b) => Number(a.sortNum) - Number(b.sortNum))
      this.dataList = list1.concat(List2)
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'role') {
        this.openDialogRole = true
      } else if (data.action === 'org_role') {
        this.openDialogOrgRole = true
      } else if (data.action === 'del') {
        this.delData()
      } else if (data.action === 'reset_pwd') {
        this.resetPassword()
      } else if (data.action === 'agent') {
        this.agent()
      } else if (data.action === 'sign_file') {
        this.showSignFile = true
        console.log('showSignFile')
      }
    },
    async agent () {
      const pwd = await this.$dialog.confirm({
        title: '系统提示',
        placeholder: '请输入校验码',
        showInput: true,
        inputType: 'password'
      })
      if (pwd) {
        let uuid = uuidv4()
        let pubKey = await authRequest.getLoginPubKey({'rsaKey': uuid})
        const result = await authRequest.agentLogin({
          userId: this.chooseData.id,
          password: rsaHelper.rsaEncrypt(pubKey.rsaPubKey, pwd),
          clientType: this.$store.getters.clientType === 'wap' ? 'mobile' : this.$store.getters.clientType,
          rsaKey: uuid
        })
        if (result && result.token) {
          this.agentModal.token = result.token
          this.agentModal.img = await QRCode.toDataURL(JSON.stringify({
            type: 'agent-login',
            token: result.token
          }))
          this.agentModal.show = true
        }
      }
    },
    pcLogin () {
      localstorageHelper.updateData('token', this.agentModal.token)
      window.location.href = '/'
    },
    async resetPassword () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定重置用户 ' + this.chooseData.name + ' 的密码吗?'})
      if (result) {
        request.resetPassword(this.chooseData.id).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '密码重置成功'
          })
        })
      }
    },
    async delData () {
      this.loading = true
      let otherDel = await delUserDeal(this, this.chooseData.id)
      if (otherDel) {
        if (otherDel === 'done') {
          this.loadData()
          this.$store.dispatch('loadUserList', true)
          this.$notice.info({
            title: '系统提示',
            desc: '用户已删除'
          })
          this.loading = false
        } else {
          this.loading = false
        }
      } else {
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除用户 ' + this.chooseData.name + ' 吗?'})
        if (result) {
          await request.del(this.chooseData.id)
          this.$notice.info({
            title: '系统提示',
            desc: '用户已删除'
          })
          this.loading = false
          this.loadData()
          this.$store.dispatch('loadUserList', true)
        } else {
          this.loading = false
        }
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (parm) {
      if (parm === 'add') {
        this.chooseData = {}
        this.openDialog = true
      } else if (parm === 'sort') {
        this.$refs.sortmodal.modal = true
      }
    },
    async loadData (reload) {
      if (!this.$authFunsProxy.get) {
        return
      }
      this.loading = true
      let parm = {
        needRoleInfo: 1
      }
      let data = await request.get(parm)
      data.forEach(v => {
        v.roleNames = v.orgRoles.filter(v1 => !v1.orgId && v1.roleId).map(v2 => v2.roleName).join(',')
        v.orgRoleLabels = v.orgRoles.filter(v1 => v1.orgId).map(v2 => v2.orgName + '/' + (v2.roleName || '无职务')).join(',')
        v.statusText = (this.userStatusList.find(v1 => v1.key === v.status) || {label: v.status}).label
      })
      if (!this.$store.getters.currentUserIsAdmin) {
        data = data.filter(v => !v.orgRoles.find(v1 => v1.roleId === 1))
      }
      this.loading = false
      this.dataList = data
      if (reload) {
        this.$store.dispatch('loadUserList', true)
      }
    }
  },
  data () {
    return {
      openDialog: false,
      openDialogRole: false,
      openDialogOrgRole: false,
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: {},
      showSignFile: false,
      agentModal: {
        show: false,
        img: null,
        token: null
      }
    }
  }
}
</script>
