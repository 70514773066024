<template>
  <fm-modal :mask-closable="true" v-model="modal" v-if="modal" width="800px" title="默认排序设置" theme="mh-blackt">
    <div style="width: 100%;height: 60vh;">
    <fm-table-new
      ref="table"
      :simple-filter="true"
      :full="true"
      auto-height
      border="row"
      size="small"
      :stripe="false"
      emptyPlaceholder="-"
      :columns="columns"
      :data-list="dataList"
    ></fm-table-new>
    </div>
  </fm-modal>
</template>

<script>
import SortNum from './sortNum'

export default {
  props: {
    dataList: {
      type: Array
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    columns () {
      return [
        { title: '姓名', field: 'name', sort: true},
        { title: '身份证号码', field: 'idNo', sort: true},
        {
          title: '默认排序',
          field: 'sortNum',
          sort: true,
          dataType: Number,
          filterRange: true,
          render: (h, rowData) => {
            return h(SortNum, {
              props: {
                data: rowData
              },
              on: {
                sortNumChange: (sortNum) => {
                  this.sortNumChange(rowData, sortNum)
                }
              }
            })
          }
        }
      ]
    }
  },
  methods: {
    sortNumChange (rowData, sortNum) {
      this.$emit('change', rowData.id, sortNum)
    }
  }
}
</script>
