<template>
  <div>
    <fm-input-new v-loadingx="loading" style="width: 5rem;" @change="change" v-model="sortNum" size="small"></fm-input-new>
  </div>
</template>

<script>
import { userRequest } from '@/api'

export default {
  props: {
    data: {
      type: Object
    }
  },
  watch: {
    data: {
      handler () {
        this.sortNum = this.data && this.data.sortNum !== null ? this.data.sortNum : null
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      sortNum: null,
      loading: false
    }
  },
  methods: {
    async change (value) {
      if (!this.data || !this.data.id) {
        this.$notice.error({
          title: '系统提示',
          desc: '数据异常。'
        })
        return
      }
      if (value && (isNaN(value) || value.includes('.'))) {
        this.sortNum = this.data && this.data.sortNum && !isNaN(this.data.sortNum) ? this.data.sortNum : null
        this.$notice.info({
          title: '系统提示',
          desc: '请输入有效整数。'
        })
      } else {
        this.loading = true
        await userRequest.updateSortNum(this.data.id, {
          sortNum: this.sortNum ? Number(this.sortNum) : null
        })
        this.$notice.success({
          title: '系统提示',
          desc: '默认排序修改完成。'
        })
        this.$emit('sortNumChange', this.sortNum ? Number(this.sortNum) : null)
        this.loading = false
      }
    }
  }
}
</script>
