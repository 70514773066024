<template>
  <div class="user-role-form" v-loading="loading">
    <div class="role-list">
      <div class="role-item" v-for="item in dataList" :key="item.id">
        <div class="org-name" v-if="item.orgId">{{item.orgName || ''}}</div>
        <div class="role-name" v-if="item.roleId">{{item.roleName || ''}}</div>
        <div class="btns">
          <!-- <div class="btn-item" style="border-right: 1px solid rgba(0,0,0,0.05);">权限</div> -->
          <div class="btn-item" @click="del(item)">删除</div>
        </div>
      </div>
      <div class="role-item" @click="openDialog = true">
        + 添加
      </div>
    </div>
    <fm-form-dialog
      form-title="添加"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import {
  userOrgRoleRequest
} from '@/api'

export default {
  props: {
    userId: { type: Number }
  },
  created () {
    this.$store.dispatch('loadRoleList')
    this.$store.dispatch('loadOrgList')
  },
  watch: {
    userId: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    formParms () {
      return [{
        type: 'select',
        selectDatas: this.$store.getters.roleList.filter(v => v.data.roleType === 'role'),
        label: '角色',
        key: 'roleId',
        check: {
          required: true
        }
      },
      {
        type: 'select',
        selectDatas: this.$store.getters.orgList,
        label: '组织架构',
        key: 'orgId'
      }]
    }
  },
  data () {
    return {
      dataList: [],
      openDialog: false,
      loading: false
    }
  },
  methods: {
    async del (item) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该角色吗?'})
      if (result) {
        userOrgRoleRequest.del(item.id).then(() => {
          this.$notify({
            title: '系统提示',
            message: '角色已删除',
            type: 'info'
          })
          this.loadData()
          this.$emit('reload')
        })
      }
    },
    async formSubmit (data, r) {
      await userOrgRoleRequest.add({
        userId: this.userId,
        roleId: data.roleId,
        orgId: data.orgId || null
      })
      this.openDialog = false
      this.loadData()
      this.$emit('reload')
      r()
    },
    async loadData () {
      if (this.userId) {
        let datas = await userOrgRoleRequest.get({
          roleType: 'role',
          userId: this.userId
        })
        this.dataList = datas
      } else {
        this.dataList = []
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-role-form {
  width: 100%;
  .role-list {
    display: flex;
    flex-wrap: wrap;
  }
  .role-item {
    cursor: pointer;
    min-width: 100px;
    padding: 10px 10px 20px;
    position: relative;
    margin: 0 20px 20px 0;
    height: 80px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px  rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .role-item:hover {
    box-shadow: 2px 2px 4px  rgba(0,0,0,0.2);
    .btns {
      display: flex;
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    border-top: 1px solid rgba(0,0,0,0.05);
    .btn-item {
      flex: 1;
      line-height: 22px;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>